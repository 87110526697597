import {NavBarItemModel} from '@core/models/navbar.model';
import {MapType} from '@core/models/map-utils';

export const navbarItems: NavBarItemModel[] = [
  {
    label: 'NAVBAR.EMPLOYEES.MAIN',
    image: 'assets/header-icons/employee.svg',
    items: [
      {
        routerLink: '/employees/positions',
        label: 'NAVBAR.EMPLOYEES.POSITIONS',
        permission: 'EMPLOYEES_POSITION',
      },
      {
        routerLink: '/employees/groups',
        label: 'NAVBAR.EMPLOYEES.GROUP',
        permission: 'EMPLOYEES_GROUP',
      },
      {
        routerLink: '/employees/workflows',
        label: 'NAVBAR.EMPLOYEES.WORKFLOWS',
        permission: 'EMPLOYEES_WORKFLOW',
      },
      {
        routerLink: '/employees/employees',
        label: 'NAVBAR.EMPLOYEES.EMPLOYEES',
        permission: 'EMPLOYEES_EMPLOYEE',
      },
    ]
  },
  {
    label: 'NAVBAR.VEHICLES.MAIN',
    image: 'assets/header-icons/vehicles.svg',
    items: [
      {
        routerLink: '/vehicles/fleet',
        label: 'NAVBAR.VEHICLES.FLEETS',
        permission: 'VEHICLES_FLEET',
      },
      {
        routerLink: '/vehicles/vehicle-types',
        label: 'NAVBAR.VEHICLES.VEHICLE_TYPES',
        permission: 'VEHICLES_VEHICLE_TYPE',
      },
      {
        routerLink: '/vehicles/fuel-types',
        label: 'NAVBAR.VEHICLES.FUEL_TYPES',
        permission: 'VEHICLES_FUEL_TYPE',
      },
      {
        routerLink: '/vehicles/tank-dimensions',
        label: 'NAVBAR.VEHICLES.TANK_DIMENSIONS',
        permission: 'VEHICLES_TANK_DIMENSION',
      },
      {
        routerLink: '/vehicles/brands',
        label: 'NAVBAR.VEHICLES.BRANDS_AND_MODELS',
        permission: 'VEHICLES_BRAND',
      },
      {
        routerLink: '/vehicles/vehicles',
        label: 'NAVBAR.VEHICLES.VEHICLES',
        permission: 'VEHICLES_VEHICLE',
      }
    ]
  },
  {
    label: 'NAVBAR.TRACKING.MAIN',
    image: 'assets/header-icons/tracking.svg',
    items: [
      {
        routerLink: '/tracking/global',
        label: 'NAVBAR.TRACKING.GLOBAL',
        permission: 'TRACKING_TRACKING_LIVE',
      }
    ]
  },
  {
    label: 'NAVBAR.LOCATIONS.MAIN',
    image: 'assets/header-icons/locations.svg',
    items: [
      {
        routerLink: '/locations/location-types',
        label: 'NAVBAR.LOCATIONS.LOCATION_TYPES',
        permission: 'LOCATIONS_LOCATION_TYPE',
      },
      {
        routerLink: '/locations/location',
        label: 'NAVBAR.LOCATIONS.LOCATIONS',
        permission: 'LOCATIONS_LOCATION',
      }
    ]
  },
  {
    label: 'NAVBAR.ROUTES.MAIN',
    image: 'assets/header-icons/route.svg',
    items: [
      {
        routerLink: '/routes/services',
        label: 'NAVBAR.ROUTES.SERVICES',
        permission: 'ROUTES_SERVICE',
      },
      {
        routerLink: '/routes/route-group',
        label: 'NAVBAR.ROUTES.ROUTE_GROUPS',
        permission: 'ROUTES_ROUTE_GROUP',
      },
      {
        routerLink: '/routes/route-list',
        label: 'NAVBAR.ROUTES.ROUTE',
        permission: 'ROUTES_ROUTE',
      }
    ],
  },
  {
    label: 'NAVBAR.BINS.MAIN',
    image: 'assets/header-icons/bins.svg',
    items: [
      {
        routerLink: '/bins/waste-types',
        label: 'NAVBAR.BINS.WASTE_TYPES',
        permission: 'BINS_WASTE_TYPE',
      },
      {
        routerLink: '/bins/bin-types',
        label: 'NAVBAR.BINS.BIN_TYPES',
        permission: 'BINS_BIN_TYPE',
      },
      {
        routerLink: '/bins/bins',
        label: 'NAVBAR.BINS.BINS',
        permission: 'BINS_BIN',
      },
    ],
  },
  {
    label: 'NAVBAR.REQUESTS.MAIN',
    image: 'assets/header-icons/requests.svg',
    items: [
      {
        routerLink: '/requests/request-type',
        label: 'NAVBAR.REQUESTS.REQUEST_TYPES',
        permission: 'REQUESTS_REQUEST_TYPE',
      },
      {
        routerLink: '/requests/request-mode',
        label: 'NAVBAR.REQUESTS.REQUEST_MODES',
        permission: 'REQUESTS_REQUEST_MODE',
      },
      {
        routerLink: '/requests/sla',
        label: 'NAVBAR.REQUESTS.SLA',
        permission: 'REQUESTS_REQUEST_SLA',
      }
    ]
  },
  {
    label: 'NAVBAR.CLIENTS.MAIN',
    image: 'assets/header-icons/requests.svg',
    items: [
      {
        routerLink: '/clients/contacts',
        label: 'NAVBAR.CLIENTS.CONTACTS',
        permission: 'CLIENTS_CONTACT',
      },
      {
        routerLink: '/clients/clients',
        label: 'NAVBAR.CLIENTS.CLIENTS',
        permission: 'CLIENTS_CLIENT',
      }
    ]
  },
  {
    label: 'NAVBAR.VIOLATIONS.MAIN',
    image: 'assets/header-icons/violation.svg',
    items: [
      {
        routerLink: '/violations/threshold',
        label: 'NAVBAR.VIOLATIONS.THRESHOLDS',
        permission: 'VIOLATIONS_VIOLATION_THRESHOLD',
      },
      {
        routerLink: '/violations/unmanaged',
        label: 'NAVBAR.VIOLATIONS.UNMANAGED',
        permission: 'VIOLATIONS_VIOLATION_UNMANAGED',
      },
      {
        routerLink: '/violations/managed',
        label: 'NAVBAR.VIOLATIONS.MANAGED',
        permission: 'VIOLATIONS_VIOLATION_MANAGED',
      }
    ],
  },
  {
    label: 'NAVBAR.INCIDENTS.MAIN',
    image: 'assets/header-icons/requests.svg',
    items: [
      {
        routerLink: '/incidents/fines',
        label: 'NAVBAR.INCIDENTS.FINES',
        permission: 'INCIDENTS_FINE',
      },
    ],
  },
  {
    label: 'NAVBAR.SETTINGS.MAIN',
    image: 'assets/header-icons/settings.svg',
    items: [
      {
        routerLink: '/settings/organization',
        label: 'NAVBAR.SETTINGS.ORGANIZATION',
        permission: 'SETTINGS_ORGANIZATION',
      },
      {
        routerLink: '/settings/access_roles',
        label: 'NAVBAR.SETTINGS.ACCESS_ROLES',
        permission: 'SETTINGS_ACCESS_ROLE',
      },
      {
        routerLink: '/settings/access-branches',
        label: 'NAVBAR.SETTINGS.ACCESS_BRANCHES',
        permission: 'SETTINGS_ACCESS_BRANCH',
      }
    ],
  },
];

const keys: MapType[] = [
  'osm',
  'mapBox_satellite',
  'mapBox_traffic',
  'esri_satellite',
];

const mapTypeButtons = keys.map((key) => ({
  key,
  label: key.replace('_', ' ')
}));

export const subNavbar: NavBarItemModel[] = [
  {
    image: 'assets/header-icons/user-icon.png',
    items: [
      {
        label: 'NAVBAR.SUB_NAVBAR.PROFILE',
        icon: 'pi pi-user',
        key: 'PROFILE',
      },
      {
        label: 'NAVBAR.SUB_NAVBAR.MAP_TYPE',
        icon: 'pi pi-map',
        items: [
          {
            radioGroup: mapTypeButtons,
          },
        ],
      },
      {
        label: 'NAVBAR.SUB_NAVBAR.SIGN_OUT',
        icon: 'pi pi-sign-out',
        key: 'SIGN_OUT',
      },
    ],
  },
];
